@import '../../variables';

.main-navigation {
  z-index: 100;
  background-color: rgba(5, 5, 5, 0.7);
  font-weight: 700;

  &.fixed-nav {
    background-color: $black;
  }
}

ul.navigation-content {
  display: flex;
  margin: 0;
  list-style: none;
  li {
    color: $white;
    &:hover,
    &:active {
      background-color: $red;
      color: $black;
    }
    &.frontpage-link {
      display: none;
    }
    a {
      display: block;
      padding: 12px 20px;
      color: $white;
      font-size: 1rem;
      text-transform: uppercase;
      text-decoration: none;
      transition: 250ms;

      &:hover,
      &:active {
        text-decoration: none;
        color: $black;
      }
    }
    > div {
      display: none;
      position: absolute;
      width: 60%;
      left: 40px;
      background-color: $red;
      margin-left: 0;
      margin-right: 0;
      padding: 20px;

      ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        li {
          color: $white;
          flex: 0 0 50%;

          a {
            display: inline-block;
            &:hover,
            &:active {
              background-color: $white;
            }
          }
        }

        div {
          a {
            padding: 0;
          }
        }
      }
    }
  }
}

ul.navigation-icons {
  display: none;
  img {
    cursor: pointer;
  }
}

@media only screen and (min-width: 992px) {
  ul.navigation-content {
    padding-left: 0;
    li {
      &:hover {
        background-color: $white;
        color: $black;
        a {
          color: $black;
        }
        > div {
          display: block;
        }
      }

      &.active {
        background-color: $white;
        color: $black;
        a {
          color: $black;
        }
      }

      a {
        font-size: 0.75rem;
        height: 100%;
        padding: 8px;
      }

      > div {
        background-color: $white;
        box-shadow: 0 6px 8px 0px rgba(0, 0, 0, 0.1);
        width: 100%;
        left: 0;

        .navigation-submenu {
          border-bottom: solid 1px #dfdfdf;
          display: flex;
          margin-bottom: 1rem;
          padding-bottom: 1rem;
        }

        figure {
          width: 33%;
          margin: 0;

          img {
            max-height: 300px;
          }
        }

        ul {
          height: fit-content;
          width: 100%;
          li {
            padding-bottom: 8px;
            &:nth-child(odd) {
              border-right: solid 1px #dfdfdf;
            }

            &:nth-child(even) {
              padding-left: 8px;
            }

            a {
              color: $black;
              padding: 12px 24px;
              border-radius: 24px;
              text-transform: capitalize;
              transition: all 250ms;
              &:hover {
                background-color: #efefef;
                color: $black;
              }
            }
          }
        }

        .social-sites-container {
          border: none;
          width: 100%;
        }

        ul.social-sites {
          border: none;
          display: flex;
          justify-content: flex-end;
          margin: 0;
          padding: 0;
          width: 100%;

          li {
            flex: 0 0 auto;
            border: none;
            padding-left: 0;
            padding-bottom: 0;

            a {
              padding: 2px 12px;

              &:hover {
                background-color: transparent;
              }
            }
          }

          img {
            max-width: 28px;

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }

  ul.navigation-icons {
    display: block;
    position: absolute;
    top: 0;
    right: 20px;
    margin: 5px 0 0 0;
    list-style: none;
    li {
      float: left;
      padding-left: 1.5rem;
      img {
        height: 18px;
      }

      span {
        font-size: 1rem;
        color: white;
        font-weight: 300;
        line-height: 1.6;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  ul.navigation-content {
    li {
      a {
        padding: 8px 16px;
      }
    }
  }
}

@media only screen and (min-width: 1240px) {
  ul.navigation-content {
    padding-left: 0;
    li {
      a {
        font-size: 0.8rem;
      }
      > div {
        left: 0;
        width: 100%;
      }
    }
  }

  ul.navigation-icons {
    display: block;
  }
}

@media only screen and (min-width: 1440px) {
  ul.navigation-content {
    padding-left: 40px;
    li {
      > div {
        left: 40px;
        width: 1312px;
      }
      a {
        font-size: 0.875rem;
        padding: 11px 18px 9px 18px;
      }
    }
  }

  ul.navigation-icons {
    right: 40px;
    margin: 9px 0 0 0;
  }
}
